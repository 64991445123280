<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        title="Delete/edit chat patterns"
      >

        <!--      <b-card-text>Delete/Edit chat patterns.</b-card-text>-->

        <!-- toggle button -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-toggle.collapse-1
          variant="outline-primary"
        >
          Edit existing chat patterns
        </b-button>

        <b-collapse
          id="collapse-1"
          class="mt-2"
        >
          <div>
            <div>
              <b-form
                ref="form"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >

                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                  :name="item.name"
                >
                  <!-- Profession -->
                  <b-col
                    md="2"
                  >
                    <b-form-group
                      label="Name"
                      label-for="Name"
                    >
                      <!--                    {{ value }}-->
                      <!--                    {{ item.id }}-->
                      {{ item.name }}
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      v-b-toggle.collapse-1
                      variant="outline-warning"
                      class="mt-0 mt-md-2"
                      @click="editItem(item.name, index)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-25"
                      />
                      <span>Edit</span>
                    </b-button>
                  </b-col>
                  <b-col
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index,item.name)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                  <b-col
                    md="2"
                  >
                    <b-form-group
                      label="Enable/Disable"
                    >
                      <b-form-checkbox
                        v-model="item.active"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        @change="changeStatus(item.name, item.active , item.uid)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>

              </b-form>
            </div>
          <!--          <b-button-->
          <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--            variant="primary"-->
          <!--            @click="repeateAgain"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="PlusIcon"-->
          <!--              class="mr-25"-->
          <!--            />-->
          <!--            <span>Add New</span>-->
          <!--          </b-button>-->
          </div>
        </b-collapse>
      </b-card>
      <b-card title="Categories">

        <!-- custom checkbox -->
        <b-form-group label="Choose categories for messages:">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selected"
            :options="options"
            name="flavour-1"
            class="demo-inline-spacing"
          />
        </b-form-group>
      <!--      <b-card-text class="mb-0">-->
      <!--        Selected: <strong>{{ selected }}</strong>-->
      <!--      </b-card-text>-->

      </b-card>
      <b-card>
        <template #header>
          <h3 class="mb-0">
            Set Messages
          </h3>
          <b-form-checkbox
            v-model="duplicatesEnabled"
            name="check-button"
            switch
            inline
            class="align-self-start"
          >
            Enable duplicates
          </b-form-checkbox>
        </template>
        <!--      <b-card-text>-->
        <!--        <span>Drop/Choose .txt File with new Messages on each Line</span>-->
        <!--      </b-card-text>-->
        <!--      <div>-->
        <!--        &lt;!&ndash; Styled &ndash;&gt;-->
        <!--        <b-form-file-->
        <!--          v-model="file"-->
        <!--          placeholder="Choose a file or drop it here..."-->
        <!--          drop-placeholder="Drop file here..."-->
        <!--          @change="selectedFile"-->
        <!--        />-->

        <!--        <b-card-text class="my-1">-->
        <!--          Selected file: <strong>{{ file ? file.name : '' }}</strong>-->
        <!--        </b-card-text>-->
        <!--      </div>-->
        <div
          v-cloak
          id="app"
        >
          <div>

            <!-- Styled -->
            <b-form-file
              v-model="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
          </div>
          <label for="textarea-auto-height" />
          <b-form-textarea
            id="textarea-auto-height"
            v-model.lazy="filetext"
            debounce="5000"
            placeholder="Insert your messages line by line"
            rows="3"
            max-rows="11"
          />
        </div>
        <div v-if="!duplicatesEnabled">

          <div class="mt-3">
            <h3>
              Or create new Messages
            </h3>
          </div>
          <b-card-text>
            <span>Messages are added by clicking pressing the</span>
            <code>Enter</code>
            <span>Key</span>
          </b-card-text>
          <div>
            <label for="tags-basic">Type a new Message and press enter</label>
            <b-form-tags
              v-model="value"
              placeholder="Enter messages"
              input-id="tags-basic"
              class="mb-2"
            />
            <li
              v-for="item in testCollection"
              :key="item.key"
            >
              {{ item }}
            </li>
          </div>
        </div>

      </b-card>
      <b-card title="">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              label="Chatpattern Name"
              label-for="basicInput"
            >
              <b-form-input
                id="Chatpattern-name"
                v-model.lazy="name"
                placeholder="Enter Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <div class="demo-inline-spacing">
              <b-button
                id="txtName"
                v-model="name"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                type="text"
                @click="insertRecord"
              >
                Save
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="cash"
            font-scale="3"
            animation="pulse"
          />
          <p id="cancel-label">
            Please buy a license
          </p>
        </div></template>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable prefer-template */

import {
  BFormTags,
  BForm,
  BFormFile,
  BCardText,
  BCard,
  // BFormFile,
  BButton,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  VBTooltip,
  BCollapse,
  VBToggle,
  BFormTextarea, BIcon, BOverlay, BIconCash,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import { codeBasic, codemultipleChoice } from './code'

// import db from '../db'
export default {
  components: {
    BCol,
    BRow,
    BOverlay,
    BFormInput,
    BFormTags,
    BButton,
    BCardText,
    BFormCheckbox,
    // BFormFile,
    BFormGroup,
    BForm,
    BCard,
    BCollapse,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BFormFile,
    BIcon,
    BFormCheckboxGroup,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      duplicatesEnabled: false,
      testCollection: [],
      edit: false,
      onEditIndex: null,
      onEditName: null,
      show: false,
      name: null,
      filetext: null,
      file: null,
      file2: null,
      general: false,
      nextTodoId: 1,
      tags: ['Test', 'MÖÖP'],
      value: ['Hi', 'How are you?', 'Great'],
      selected: [],
      options: [
        { text: 'General', value: 'General' },
        { text: 'Stream starting', value: 'StreamStarting' },
        { text: 'Mario Kart', value: 'MarioKart' },
        { text: 'Just Chatting', value: 'JustChatting' },
        { text: 'Marbles', value: 'Marbles' },
        { text: 'League of Legends', value: 'League' },
        { text: 'Valorant', value: 'Valorant' },
      ],
      items: [],
      codeBasic,
      codemultipleChoice,
    }
  },
  watch: {
    value(val) {
      this.filetext = val.join('\n')
    },
    file() {
      // eslint-disable-next-line prefer-destructuring
      const file = this.file
      if (!file || file.type !== 'text/plain') return

      // Credit: https://stackoverflow.com/a/754398/52160
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = evt => {
        this.filetext = evt.target.result
        this.value = evt.target.result.split('\n')
        // eslint-disable-next-line no-global-assign,no-restricted-globals
        // this.name = evt.target.result
      }
      reader.onerror = evt => {
        console.error('ERROR')
        console.error(evt)
      }
    },
    selected(val) {
      // eslint-disable-next-line no-unused-vars
      new Promise((resolve, reject) => {
        if (val.includes('General') && !this.general) {
          this.selected = ['General', 'StreamStarting', 'MarioKart', 'JustChatting', 'Marbles', 'League', 'Valorant']
          this.general = true
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('true')
        }
        resolve('true')
      }).then(() => {
        if (this.general && !val.includes('General')) {
          this.general = false
        }
        // console.log('VALINTHEN' + val)
        // console.log(`ExistGeneral ${val.includes('General')}   ThisGeneral${this.general}  Other ${!val.includes('MarioKart') || !val.includes('JustChatting') || !val.includes('Marbles') || !val.includes('League')}`)
        if (val.includes('General') && this.general && ((!val.includes('MarioKart') || !val.includes('Marbles') || !val.includes('JustChatting') || !val.includes('League')))) {
          this.selected.splice(this.selected.indexOf('General'), 1)
        }
      })
    },
    filetext(val) {
      this.value = val.split('\n')
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    // Get Existing Chat pattern
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        if (snap.data().Package === 'None') {
          this.show = true
        }
      })
    sx.collection('User')
      .doc(userData.id)
      .collection('Chatlists')
      .onSnapshot(snap => {
        this.items = []
        snap.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // const test = doc.data()
          this.items.push({
            id: this.nextTodoId += 1,
            name: doc.data().name,
            active: doc.data().active,
            uid: doc.id,
            textlength: doc.data().text.length,
          })
        })
      })
  },
  methods: {
    changeStatus(name, activebool, uid) {
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .doc(uid)
        .update(
          { active: activebool },
        )
    },
    tesst() {
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .where('name', '==', 'Heyho')
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            const test = doc.data()
            // console.log(test)
            this.selected = test.Category
            this.value = test.text
            this.name = test.name
            // console.log(doc.id, ' XX=> ', doc.data())
          })
        })
        .catch(error => {
          console.error('Error getting documents: ', error)
        })
    },
    selectedFile() {
      // console.log('selected a file')
      // console.log(this.$refs.myFile.files[0])
      //
      // const file = this.$refs.myFile.files[0]
      // console.log(this.$refs.myFile.files[0])
      const { file } = this.file
      if (!file || file.type !== 'text/plain') return

      // Credit: https://stackoverflow.com/a/754398/52160
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = evt => {
        this.filetext = evt.target.result
        this.value = evt.target.result.split('\n')
        // eslint-disable-next-line no-global-assign,no-restricted-globals
        // this.name = evt.target.result
      }
      reader.onerror = evt => {
        console.error('ERROR')
        console.error(evt)
      }
    },
    updateRecord() {
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .get()
        .then(snapshot => {
          const document = snapshot.data()
          // console.log(document.data())
          return document
          // do something with document
        })
        .catch(error => {
          console.error(error)
        })
    },
    editItem(name, index) {
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .where('name', '==', name)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            const test = doc.data()
            // console.log(test)
            this.selected = test.Category
            this.value = test.text
            this.name = test.name
            // console.log(doc.id, ' XX=> ', doc.data())
          })
          this.edit = true
          this.onEditIndex = index
          this.onEditName = name
        })
        .catch(error => {
          console.error('Error getting documents: ', error)
        })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeItem(index, name) {
      this.items.splice(index, 1)
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .where('name', '==', name)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id)
            db.collection('User')
              .doc(userData.id)
              .collection('Chatlists')
              .doc(doc.id)
              .delete()
              .then(() => {
                console.log('Deleted document')
              })
          })
        })
        .catch(error => {
          console.error('Error getting documents: ', error)
        })
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    insertRecord() {
      if (this.edit) {
        // console.log(this.onEditIndex, this.onEditName)
        this.removeItem(this.onEditIndex, this.onEditName)
      }
      // const userData = getUserData()
      const db = this.$firebase.firestore()
      const citiesRef = db.collection('User')
      // eslint-disable-next-line no-plusplus
      citiesRef.doc(getUserData().id).collection('Chatlists').add({
        name: this.name,
        text: this.value,
        Category: this.selected,
        active: true,
      })
      // eslint-disable-next-line no-unused-vars
        .then(docref => {
          // this.items.push({
          //   id: this.nextTodoId += 1,
          //   name: this.name,
          // })
          // console.log('Collection Created')
          // console.log(docref.id)
          this.$swal({
            title: 'Succeed!',
            text: ' New Chatlist added!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          console.error('Error adding document: ', error)
        })
      if (this.edit) {
        this.edit = false
      }
      // this.value = []
      // this.name = null
    },
  },
}
</script>
